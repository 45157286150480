@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  line-height: normal;
  color: #404040;
}

#root {
  height: 100%;
}

/* theme color setup for text and background start */
.text-white {
  color: $white;
}

.text-danger {
  color: $danger;
}

.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

.text-warning {
  color: $warning;
}

.text-success {
  color: $success;
}

.text-info {
  color: $info;
}

.bg-danger {
  background-color: $danger;
}

.bg-primary {
  background-color: $primary;
}

.bg-lightprimary {
  background-color: $lightprimary;
}

.bg-secondary {
  background-color: $secondary;
}

.bg-warning {
  background-color: $warning;
}

.bg-success {
  background-color: $success;
}

.bg-info {
  background-color: $info;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/* theme color setup for text and background end */

.fw-bold {
  font-weight: bold;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.mx-w100 {
  max-width: 100%;
}

.d-flex {
  display: flex;
}

.flex-0 {
  flex: 0;
}

.flex-1 {
  flex: 1;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.img-fluid {
  width: auto;
  max-width: 100%;
}

/* common css start */

.section-padding {
  padding: 130px 0px;
}

/* common css emd */

/* home page css start */
.hero-banner {
  width: 100%;
  min-height: calc(100vh - 92px);
  background: rgb(73, 136, 127);
  background: linear-gradient(-180deg,
      rgba(73, 136, 127, 1) 0%,
      rgba(80, 187, 202, 1) 100%);
}

.about-slider {
  &.slick-slider {
    .slick-list {
      padding: 0px 10px !important;

      .slick-track {
        display: flex;
        align-items: center;

        .slick-slide {
          padding: 0px 0px;

          & .prev-slide img {
            opacity: 0;
          }

          img {
            width: 100%;
            max-width: 75%;
            opacity: 0.5;
          }

          &.slick-current img {
            max-width: 100%;
            opacity: 1;
          }
        }
      }
    }

    .custom-arrow {
      position: absolute;
      bottom: -55px;

      &.custom-prev-arrow {
        left: calc(0% - 0px);
        transform: translateX(0%);
        cursor: pointer;
      }

      &.custom-next-arrow {
        right: calc(85% - 30px);
        transform: translateX(-85%);
        cursor: pointer;
      }
    }
  }
}

.LMS-content {
  padding: 0px 40px 0px 40px;
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    width: 5px;
    height: 115px;
    background-color: #404041;
    content: "";
    z-index: 1;
  }
}

.chronicles-slider {
  &.slick-slider {
    margin-top: 90px;

    .slick-slide {
      margin-top: 50px;

      .chronicles-slide-info {
        padding: 0px 0px 0px 40px;
        max-width: 460px;
      }
    }

    .slick-dots {
      bottom: auto;
      top: -90px;

      ul {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
        display: flex;
        align-items: self-start;

        li {
          width: auto;
          height: auto;
          line-height: inherit;
          border-top: 1px solid rgba(255, 255, 255, 0.5);
          padding: 10px 0px;
          margin: 0px;
          flex: 1;

          &.slick-active {
            border-top: 3px solid rgba(255, 255, 255, 1);

            .chronicles-pagination {
              color: rgba(255, 255, 255, 1);
            }
          }

          .chronicles-pagination {
            width: 100%;
            max-width: 200px;
            font-size: 16px;
            line-height: 25px;
            font-weight: normal;
            text-align: left;
            color: rgba(255, 255, 255, 0.5);

            span {
              display: block;
            }
          }
        }
      }
    }

    .custom-arrow {
      position: absolute;
      bottom: -55px;

      &.custom-prev-arrow {
        left: calc(50% - 30px);
        transform: translateX(-50%);
        cursor: pointer;
      }

      &.custom-next-arrow {
        right: calc(50% - 30px);
        transform: translateX(50%);
        cursor: pointer;
      }
    }
  }
}

.overview-box {
  border-radius: 8px;
  background-color: $white;
  position: relative;
  overflow: hidden;

  &:hover {
    cursor: pointer;

    .overview-box-hover {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.75);
      border-radius: 8px;
      -webkit-animation-name: fadeInUp;
      animation-name: fadeInUp;
      animation-duration: 1.8s;

      .overview-info {
        color: #fff;

        .overview-info-list {
          ul {
            margin: 0px;
            padding-left: 20px;

            li {
              margin-bottom: 15px;

              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }

  .overview-thumb {
    img {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  .overview-info {
    padding: 8px 24px 30px 24px;
    min-height: 265px;
  }

  .overview-box-hover {
    display: none;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.vision-slider-outer {
  position: relative;
  overflow: hidden;
  height: 400px;

  .vision-slider {
    &.slick-slider {
      margin-right: -400px;

      .slick-slide {
        padding: 0px 25px 0px 0px;
      }

      .custom-arrow {
        position: absolute;
        bottom: -22%;
        cursor: pointer;
        border-radius: 50%;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        border: 1px solid #ffff;

        &.custom-prev-arrow {
          left: 52%;
          transform: translateX(-100%);
          cursor: pointer;
        }

        &.custom-next-arrow {
          right: 47%;
          transform: translateX(100%);
          cursor: pointer;
        }
      }
    }
  }
}

.vision-slide-info {
  padding: 0px 0px 0px 40px;
  // max-width: 500px;
}

.goal-area {
  background-color: #e5ebef;
}

.statistics-slider {
  &.slick-slider {
    .slick-slide {}

    .slick-dots {
      position: relative;
      bottom: -30px;

      li {
        width: 20px;
        height: 20px;

        button {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.5);
          position: relative;
          padding: 0px;
          display: block;

          &::before {
            border: 2px solid rgba(255, 255, 255, 1);
            border-radius: 100px;
            content: "";
            height: 21px;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: all 0.4s;
            width: 21px;
          }
        }

        &:first-child {
          margin-left: 0px;
        }

        &:last-child {
          margin-right: 0px;
        }
      }

      & .slick-active {
        button {
          background-color: rgba(255, 255, 255, 1);

          &::before {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
          }
        }
      }
    }
  }
}

.footer {
  .footer-top {
    padding: 50px 0px 100px 0px;
    border-bottom: 0.5px solid #ffffff;
  }

  .footer-links {
    color: #404040;
  }

  .footer-bottom {
    padding: 15px 0px;
  }
}

.program-box {
  img {
    transition: transform 0.3s ease;

    &:hover {
      cursor: pointer;
    }
  }
}

/* home page css end */

/* auth page css start */
.auth-page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;

  .auth-left {
    width: 45%;
    height: 100%;

    .auth-left-wrap {
      .auth-left-form {
        max-width: 445px;
        margin: 0px auto;
        padding-top: 40px;
      }
    }
  }

  .auth-right {
    width: 55%;
    height: 100%;
    background: rgb(73, 136, 127);
    background: linear-gradient(-180deg,
        rgba(73, 136, 127, 1) 0%,
        rgba(80, 187, 202, 1) 100%);

    .auth-thumb {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      max-width: 550px;
      margin: 0px auto;
    }
  }
}

.theme-form {
  .form-group {
    margin-top: 30px;

    .form-label {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }
}

/* auth page css end */

/* dashboard page start */
.main-layout {
  background-color: #fff;
  padding: 30px;
  margin-top: 75px;
  min-height: 100%;
}

.white-background {
  background-color: #fff;
}

.topbar {
  background-color: #f8f8f8;
  box-shadow: 0px 0px 4px 0px #00000029;

  .MuiToolbar-root {
    min-height: 72.5px;
  }

  .topbarRight {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}

.dashboard-factbox {
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.Toastify {
  .Toastify__toast {
    font-family: "Open Sans", "Arial", sans-serif;
    font-size: 16px;
  }
}

/* dashboard page end  */

/* study plan css start */
.plan-info-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 12px;

  .single-info-row {
    display: block;
    flex: 1;
  }
}

.plan-creditinfo {
  height: 100%;
  width: 90%;
  padding-right: 6px;
  position: relative;

  &.lg-en {
    border-left: 1px solid #4dadad;

    &:before {
      left: -3px;
    }
  }

  &:before {
    position: absolute;
    top: 0;
    width: 5px;
    height: 115px;
    background-color: #4dadad;
    content: "";
    z-index: 1;
    border-radius: 5px;
  }

  &.lg-ar {
    border-right: 1px solid #4dadad;
    padding-right: 16px;

    &:before {
      right: -3px;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4dadad;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #4dadad;
  }
}

.overview-info-list {
  height: 300px;
  overflow: auto;
  padding-right: 20px;

  ul {
    margin: 0px;
  }
}

.overview-info-list::-webkit-scrollbar {
  width: 2px;
  border-radius: 20px;
}

.overview-info-list::-webkit-scrollbar-track {
  background: #e4ebef;
  border-radius: 20px;
}

.overview-info-list::-webkit-scrollbar-thumb {
  background: #1aaead;
  border-radius: 20px;
}

.overview-info-list::-webkit-scrollbar-thumb:hover {
  background: #1aaead;
}

/* study plan css end */
.bg-gray-color {
  background: #E5EBEF;
}

.contact-us-icon svg {
  font-size: 50px;
}

.contact-us-container {
  background-color: #fff;
  padding: 2rem 1rem;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.09);
  border-radius: .5rem;
}

.contact-us-heading {
  color: #125c86;
  text-align: center;
}