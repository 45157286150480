// Define breakpoints as variables
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

@media (max-width: $breakpoint-xl) {
}
@media (max-width: $breakpoint-lg) {
}
@media (max-width: $breakpoint-md) {
}
@media (max-width: $breakpoint-sm) {
  .logo {
    max-height: 50px;
  }
  .LMS-content,
  .vision-slide-info {
    padding: 0px 15px 0px 15px;
  }

  .chronicles-slider {
    &.slick-slider {
      margin-top: 0px;
      .slick-slide {
        margin-top: 0px;
        .chronicles-slide-info {
          padding: 0px 0px 0px 0px;
          max-width: 100%;
        }
      }
      .slick-dots {
        display: none;
      }
    }
  }
  .footer {
    .footer-links {
      display: block;
      width: 100%;
      margin: 7px 0px;
    }
  }
  .custom-arrow {
    &.custom-prev-arrow {
      left: calc(0% - 15px) !important;
      transform: translateX(0%) !important;
      cursor: pointer;
    }
    &.custom-next-arrow {
      transform: translateX(-0%) !important;
      cursor: pointer;
    }
  }
}
