$white: #ffffff;

//error
$danger: #f27373;

// primary
$primary: #50bbca;

// light primary
$lightprimary: #f1f9fb;

// secondary
$secondary: #5d8da5;

// warning
$warning: #e87b16;

// success
$success: #06c825;

// info
$info: #12aee0;

// ==============================|| JAVASCRIPT ||============================== //

:export {
  white: $white;

  //error
  danger: $danger;

  // primary
  primary: $primary;

  // light primary
  lightprimary: $lightprimary;

  // secondary
  secondary: $secondary;

  // warning
  warning: $warning;

  // success
  success: $success;

  // info
  info: $info;
}
